<template>
    <div>
<!--                <el-carousel :height="computeHeight">-->
<!--                    <el-carousel-item v-for="(item, index) in sliders" :key="index">-->
<!--                        <img :src="item.img" class="slider_img">-->
<!--                    </el-carousel-item>-->
<!--                </el-carousel>-->

        <div style="height: 35px">

        </div>
        <di style="border: 0px solid blue;display: flex;justify-content: space-around;align-items: center">
            <div>
<!--                <img style="width: 127px;height: 31px;" src="../image/logo.png">-->
            </div>

<!--            <di style="border: 0px solid red;display: flex; gap: 12px;">-->
<!--                <div>-->
<!--                    <el-link :underline="false" style="font-size: 18px;font-weight: bold;color: black" href="" target="_blank">注册</el-link>-->
<!--                </div>-->

<!--                <div>-->
<!--                    <el-link :underline="false" style="font-size: 18px;font-weight: bold;color: black" href="" target="_blank">制证</el-link>-->
<!--                </div>-->

<!--                <div>-->
<!--                    <el-link :underline="false" style="font-size: 18px;font-weight: bold;color: black"  target="_blank" @click="tabTar">联系我们</el-link>-->
<!--                </div>-->

<!--            </di>-->

        </di>

        <div style="height: 35px">

        </div>
        <!-- 手机端商品展示 -->
<!--        <div id="产品微观" class="head-label">-->
<!--            <div style="text-align: center;" class="font_size_24">-->
<!--                产品微观介绍-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="product_mobile">-->
<!--            <el-row>-->
<!--&lt;!&ndash;                <el-col :xs="24" :sm="12">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="product_mobile_content">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="product_mobile_title font_size_30">{{products[0].title}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="product_font_style product_mobile_content_one">{{products[0].content[0]}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="product_font_style product_mobile_content_two">{{products[0].content[1]}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="product_font_style product_mobile_content_two">{{products[0].content[2]}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </el-col>&ndash;&gt;-->
<!--&lt;!&ndash;                <el-col :xs="24" :sm="12">&ndash;&gt;-->
<!--&lt;!&ndash;                    <img :src="products[0].img[0]" class="product_mobile_img"/>&ndash;&gt;-->
<!--&lt;!&ndash;                    <img :src="products[0].img[1]" class="product_mobile_img"/>&ndash;&gt;-->
<!--&lt;!&ndash;                </el-col>&ndash;&gt;-->
<!--            </el-row>-->
<!--        </div>-->
        <!-- 后台管理 -->
        <div class="product_mobile">
            <el-row>
                <el-col :xs="24" :sm="12">
                    <div class="product_pc_one">
                        <div class="product_mobile_title font_size_30">{{manages[0].title}}</div>
                        <div style="margin-top: 20px" class="product_font_style">{{manages[0].content[0]}}</div>
                        <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[1]}}</div>
                        <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[2]}}</div>
                        <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[3]}}</div>
                        <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[4]}}</div>
                        <div>
<!--                        <img style="width: 297px;height: 151px;" src="../image/shhazl.png">-->
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12">
                    <img :src="manages[0].img[0]" class="product_pc_img"/>
                </el-col>
            </el-row>
        </div>
        <!-- 产品特点-->
        <div id="产品特点" class="product_spec_title head-label">
            <div style="text-align: center;" class="font_size_24">
                产品特点
            </div>
        </div>
        <div class="product_spec_parent">
            <div class="border_one border_spec">
                <div class="border_spec_inner_one">
                    <img :src="spec.product[0].img" style="width: 50px;height: 50px"/>
                    <div style="margin-left:5px; font-weight: bold;" class="font_size_24">
                        {{spec.product[0].title}}
                    </div>
                </div>
                <div style="display: flex;flex-direction: column;">
                    <div class="border_spec_content_one">
                        {{spec.product[0].contents[0]}}
                    </div>
                    <div class="border_spec_inner_last">
                        {{spec.product[0].contents[1]}}
                    </div>
                </div>
            </div>
            <div>
                <img :src="computeArrowImg" class="border_spec_img"/>
            </div>
            <div class="border_spec">
                <div class="border_spec_inner_one">
                    <img :src="spec.product[1].img" style="width: 50px;height: 50px"/>
                    <div style="margin-left:5px; font-weight: bold;" class="font_size_24">
                        {{spec.product[1].title}}
                    </div>
                </div>
                <div style="display: flex;flex-direction: column;">
                    <div class="border_spec_content_one">
                        {{spec.product[1].contents[0]}}
                    </div>
                    <div class="border_spec_inner_last">
                        {{spec.product[1].contents[1]}}
                    </div>
                </div>
            </div>
            <div>
                <img :src="computeArrowImg" class="border_spec_img"/>
            </div>
            <div class="border_spec">
                <div class="border_spec_inner_one">
                    <img :src="spec.product[2].img" style="width: 50px;height: 50px"/>
                    <div style="margin-left:5px; font-weight: bold;" class="font_size_24">
                        {{spec.product[2].title}}
                    </div>
                </div>
                <div style="display: flex;flex-direction: column;">
                    <div class="border_spec_content_one">
                        {{spec.product[2].contents[0]}}
                    </div>
                    <div class="border_spec_inner_last">
                        {{spec.product[2].contents[1]}}
                    </div>
                </div>
            </div>
            <div>
                <img :src="computeArrowImg" class="border_spec_img"/>
            </div>
            <div class="border_spec" style="margin-right: 0">
                <div class="border_spec_inner_one">
                    <img :src="spec.product[3].img" style="width: 50px;height: 50px"/>
                    <div style="margin-left:5px; font-weight: bold;" class="font_size_24">
                        {{spec.product[3].title}}
                    </div>
                </div>
                <div style="display: flex;flex-direction: column;">
                    <div class="border_spec_content_one">
                        {{spec.product[3].contents[0]}}
                    </div>
                    <div class="border_spec_inner_last">
                        {{spec.product[3].contents[1]}}
                    </div>
                </div>
            </div>
        </div>
        <!-- 合作伙伴-->
<!--        <div id="合作伙伴" class="cooperation head-label">-->
<!--            <div style="text-align: center;" class="font_size_24">-->
<!--                合作伙伴-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="cooperation_parent">-->
<!--            <div style="margin: 0 20px; text-align: center">-->
<!--                <img style="height: 90px;width: 90px" src="../../static/other/新区便民服务中心-品牌logo.jpg"/>-->
<!--                <div>新区便利服务中心</div>-->
<!--            </div>-->
<!--            <div style="margin: 0 20px; text-align: center">-->
<!--                <img style="height: 90px;width: 90px;border-radius: 50%;" src="../../static/other/勇丽调味-品牌logo.jpg"/>-->
<!--                <div>勇丽调味商行</div>-->
<!--            </div>-->
<!--        </div>-->

        <!-- 关于我们-->
        <div id="关于我们" class="about_me head-label">
            <div style="text-align: center;" class="font_size_24">
                关于我们
            </div>
        </div>
        <div style="width: 100%;margin-top: 10px">
            <div style="display: flex; flex-direction: column;width: 100%;align-items: center">

                <p class="about_me_p" style="display: flex;align-items: center">
                    <el-link :underline="false" style="font-size: 20px;letter-spacing: 1px;word-spacing: 30px" target="_blank">电话：021-20893508 </el-link>


                    <el-link :underline="false" href="https://map.baidu.com/search/%E4%B8%8A%E6%B5%B7%E5%B8%82%E5%8D%9A%E6%88%90%E8%B7%AF850%E5%8F%B7/@13525143.125,3634980,19z?querytype=s&da_src=shareurl&wd=%E4%B8%8A%E6%B5%B7%E5%B8%82%E5%8D%9A%E6%88%90%E8%B7%AF850%E5%8F%B7&c=289&src=0&pn=0&sug=0&l=12&b=(13461825.31,3611130.64;13584705.31,3671098.64)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1"
                             style="font-size: 20px;" target="_blank;">地址：上海市博成路850号</el-link><img style="height: 20px;" src="../image/地图-地标.png">
                </p>

                <p class="about_me_p" style="display: flex;align-items: center">
                    <img style="height: 20px" src="../image/xg.gif">
                    <img style="height: 20px;margin-left: 5px" src="../image/ga.png">
<!--                    <el-link target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?spm=5176.21213303.J_9220772140.109.332c53c9FXWGYd" :underline="false"-->
<!--                             style="font-size: 16px;margin-left: 5px">京公网安备 11010802017518</el-link>-->
                    <el-link target="_blank"  href="https://beian.miit.gov.cn/" :underline="false" style="font-size: 14px;">{{"\xa0\xa0"}}沪ICP备2022024219号-2</el-link>
                </p>



                <p class="about_me_p" style="font-size: 20px">
                    <el-link target="_blank" :underline="false" style="font-size: 16px; color: black" disabled="true" >Copyright © 2013 - 2022 Tencent Cloud. All Rights Reserved. 上海有铂科技有限公司 版权所有 </el-link>
<!--                    <el-link target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?spm=5176.21213303.J_9220772140.109.332c53c9FXWGYd" :underline="false" style="font-size: 14px">京公网安备 11010802017518</el-link>-->
                    <el-link target="_blank"  href="https://beian.miit.gov.cn/" :underline="false" style="font-size: 16px;">{{"\xa0\xa0"}}沪ICP备2022024219号-2</el-link>
                </p>
            </div>
        </div>

    </div>
</template>
<script>

    export default {
        components: {


        },
        name: 'App',
        data() {
            return {
                sliders: [],
                image: 'image/logo.png',
                gn: 'image/ga.png',
                images: [

                    {
                        img: require('../../static/other/用户管理.png'),
                        title: "用户管理",
                        content: ["用户消费分析", "用户标签设定"]
                    },
                    {
                        img: require('../../static/other/店铺管理.png'),
                        title: "店铺管理",
                        content: ["店铺模板切换", "店铺商品上架"]
                    },
                    {
                        img: require('../../static/other/数据分析.png'),
                        title: "数据分析",
                        content: ["交易量直观分析", "经营效果智能分析"]
                    }, {
                        img: require('../../static/other/用户构建.png'),
                        title: "用户互动",
                        content: ["营销活动通知", "用户粘连度提升"]
                    },
                    {
                        img: require('../../static/other/品牌渠道.png'),
                        title: "品牌渠道",
                        content: ["打造店铺自主品牌", "品牌多样化管理"]
                    }
                ],
                products: [
                    {
                        img: [require('../../static/other/手机端商品.png'), require('../../static/other/手机端商品SKU.png')],
                        title: "手机端商品展示 满足多规格显示",
                        content: ["商品热销分类显示 分类导航显示所有分类", "热销商品优先排序 商城首页轮播自定义",
                            "商品多规格多价格展示 商品直接在线购买支付"
                        ]
                    }
                ],
                manages: [
                    {
                        // img: [require('../../static/other/图片1.png')],
                        img: [require('../../static/other/bb.png')],
                        title: "零代码后端管理系统+低代码小程序一体化系统管理平台",
                        content: ["管理系统自定义发布 微信小程自定义发布", "规范的API接口 自定义设置",
                             "云开发 实时掌握开发追踪状态",
                        ]
                    }
                ],
                spec: {
                    img: require('../../static/other/arrow.png'),
                    product: [
                        {
                            img: require('../../static/other/手指.png'),
                            title: "开发工具",
                            contents: ["零代码后端", "低代码"]
                        },
                        {
                            img: require('../../static/other/助力零售.png'),
                            title: "注册",
                            contents: ["人证", "车证"]
                        },
                        {
                            img: require('../../static/other/数据分析.png'),
                            title: "制证",
                            contents: ["实体证", "电子证"]
                        },
                        {
                            img: require('../../static/other/店铺管理.png'),
                            title: "门禁",
                            contents: ["数字哨兵", "PAD手持机"]
                        }
                    ]
                },
                company: [
                    "企业从事于：互联网整合营销，互联网软件开发，微信公众号，小程序，微商城开发，提供技术支持，是所创业型服务研发公司。",
                    "核心理念：打造移动电子商务平台 ，提供一键式部署，实现简单可靠稳定的商务平台。",
                    "团队作风：我们来自不同地区，因梦想相聚，组建团队，每个团队成员如同人的五官，缺一不可。",
                    "企业精神：以实力为盾，自信为矛。我们坚信成功靠朋友，成长靠对手，成就靠团体。",
                    "Copyright © 2013 - 2022 Tencent Cloud. All Rights Reserved. 上海海岸展览服务有限公司 版权所有 京公网安备 11010802017518 粤B2-20090059-1"
                ],
                carousel_height: '550px',
            }
        },
        methods: {
            tabTar() {
                this.$notify({
                    dangerouslyUseHTMLString: true,
                    title: '关注公众号',
                    message: '<img style="width: 250px;height: 219px" src="https://hxx-uexpo.oss-cn-shanghai.aliyuncs.com/xcx/weixinImg.jpg">',
                    duration: 0
                });
            },

            // tabTar(){
            //     console.log("....")
            // }

        },
        mounted() {
            this.sliders = [
                {
                    img: require("../../static/slider/a1.jpg")
                },
                {
                    img: require("../../static/slider/a2.jpg")
                },
                {
                    img: require('../../static/slider/a3.jpg')
                },
                {
                    img: require('../../static/slider/a4.jpg')
                }
            ]
        },
        computed: {
            //计算高度
            computeHeight() {
                let screenHeight = document.body.clientWidth;
                if (screenHeight > 415) {
                    return '550px';
                } else {
                    return '250px';
                }
            },
            computeArrowImg() {
                let screenHeight = document.body.clientWidth;
                if (screenHeight > 450) {
                    return require('../../static/other/arrow.png');
                } else {
                    return require('../../static/other/arrow_down.png');
                }
            }

        },
    }
</script>

<style scoped>


    .border_parent {
        width: 90%;
        margin: auto;
    }

    .slider_img {
        height: 550px;
        width: 100%;
    }

    .solve {
        margin-top: 30px;
        margin-bottom: 10px
    }

    .border {
        border-radius: 6px;
        border: 1px solid #EBEEF5;
        height: auto;
        margin: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
    }

    .border_one {
        margin-left: 0;
    }

    .border_img_parent {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .product_mobile_content {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .product_mobile_img {
        width: 50%;
    }

    .product_mobile_title {
        font-weight: bold;
        text-align: center;
    }

    .product_pc_one {
        text-align: center;
        min-height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .font_size_30 {
        font-size: 1.875em;
    }

    .product_font_style {
        font-size: 1.125em;
    }

    .product_pc_img {
        width: 100%
    }

    .product_pc_img_div {
        width: 50%;
        float: left;
        display: flex;
        align-items: center;
    }

    .product_pc {
        display: flex;
        width: 90%;
        margin: 20px auto;
        background: #F2F6FC;
        align-items: center;
    }

    .product_spec_title {
        margin-top: 30px;
        margin-bottom: 10px
    }

    .product_spec_parent {
        display: flex;
        width: 90%;
        margin: 20px auto;
        align-items: center;
        height: auto
    }

    .cooperation_parent {
        display: flex;
        justify-content: center;
        width: 90%;
        margin: 20px auto;
        align-items: center;
        height: auto
    }

    .border_spec {
        border-radius: 6px;
        border: 1px solid #EBEEF5;
        float: left;
        width: 25%;
        height: auto;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .about_me_p {
        font-size: 1.25em;
        font-weight: normal;
        padding: 0 20px;
        margin: 10px;
    }

    .border_title {
        font-family: "PingFang SC";
        margin-top: 10px;
        font-size: 1.125em;
        font-weight: bold;
        text-align: center;
    }

    .border_content_one {
        margin-top: 20px;
        font-size: 1.125em;
        text-align: center;
    }

    .border_content_two {
        margin-top: 5px;
        font-size: 1.125em;
        margin-bottom: 25px;
        text-align: center;
    }

    .img_border {
        margin-top: 15px;
        width: 80px;
        height: 80px
    }

    .border_spec_inner_one {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
        height: 70px;
    }

    .border_spec_content_one {
        margin-top: 15px;
        font-size: 1.125em;
        float: left;
        text-align: center;
    }

    .border_spec_inner_last {
        margin-top: 15px;
        font-size: 1.125em;
        float: left;
        margin-bottom: 25px;
        text-align: center;
    }

    .cooperation {
        margin-top: 30px;
        width: 100%;
    }

    .about_me {
        display: flex;
        margin-top: 30px;
        justify-content: center;
        width: 100%;
    }

    .product_mobile {
        width: 90%;
        align-items: center;
        margin: 20px auto;
        background: #F2F6FC;
    }

    .product_mobile_img_div {
        width: 50%;
        height: auto;
    }

    .product_mobile_content_one {
        margin-top: 20px;
        text-align: center;
    }

    .product_mobile_content_two {
        margin-top: 10px;
        text-align: center;
    }

    /* 屏幕设备适配 max-device-width: 400px */
    @media screen and (max-device-width: 415px) {
        .slider_img {
            height: 250px;
            width: 100%;
        }


        .border_parent {
            display: flex;
            width: 90%;
            margin: auto;
            height: auto;
            flex-direction: column;
        }

        .border {
            border-radius: 6px;
            border: 1px solid #EBEEF5;
            float: left;
            width: 100%;
            height: auto;
            margin: 10px 0 auto;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
            /*background: aquamarine;*/
        }

        .border_img_parent {
            margin-top: 0;

        }

        .product_mobile {
            width: 90%;
            align-items: center;
            margin: 20px auto;
            height: auto;
            background: #F2F6FC;
        }

        .product_mobile_content {
            width: 100%;
            margin-top: 15px;
        }

        .product_mobile_content_one {
            margin-top: 5px;
        }

        .product_mobile_content_two {
            margin-top: 5px;
        }

        .product_mobile_img_div {
            display: flex;
            width: 100%;
            height: auto;
            justify-content: center;
            margin-top: 10px;
            margin-bottom: 15px;
        }

        .product_mobile_img {
            float: left;
        }

        .product_mobile_title {
            font-weight: bold;
        }

        .product_pc_one {
            width: 100%;
            margin-top: 15px;
        }

        .font_size_30 {
            font-size: 1.25em;
        }

        .product_font_style {
            font-size: 1em;
        }

        .product_pc_img {
            height: 280px;
            width: 320px;
        }

        .product_pc_img_div {
            width: 100%;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
        }

        .product_pc {
            display: flex;
            width: 90%;
            height: auto;
            margin: 20px auto;
            background: #F2F6FC;
            flex-direction: column;
        }

        .head-label {
            display: flex;
            margin-top: 20px;

        }

        .product_spec_parent {
            display: flex;
            width: 90%;
            align-items: center;
            justify-content: center;
            margin: 20px auto;
            height: auto;
            flex-direction: column;
        }

        .border_spec {
            float: left;
            height: auto;
            margin: 8px;
            width: 100%;
            border-radius: 6px;
            border: 1px solid #EBEEF5;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }


        .border_title {
            font-family: "PingFang SC";
            margin-top: 5px;
            font-size: 1.125em;
            font-weight: bold;
        }

        .border_content_one {
            margin-top: 5px;
            font-size: 1.125em;
        }

        .border_content_two {
            margin-top: 5px;
            font-size: 1.125em;
            margin-bottom: 8px;
        }

        .img_border {
            margin-top: 8px;
            width: 80px;
            height: 80px
        }

        .border_spec_inner_one {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 8px;
            /*background-color: aqua;*/
            height: 70px;
        }

        .border_spec_content_one {
            margin-top: 5px;
            font-size: 1.125em;
            float: left;
        }

        .border_spec_inner_last {
            /*background-color: bisque;*/
            margin-top: 5px;
            font-size: 1.125em;
            float: left;
            margin-bottom: 8px;
        }

        .about_me {
            display: flex;
            margin-top: 10px;
            width: 100%;
        }
    }

    .border_spec_img {
        width: 75px;
        height: 55px;
        align-self: center
    }

    .font_size_24 {
        font-size: 1.5em;
    }
</style>
