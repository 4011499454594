import { createApp } from 'vue'
import App from './components/App.vue'

import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'


createApp(App)
    .use(ElementPlus)
    .mount('#app')
